import React, { useEffect, useState } from "react";
import { Card, Col } from "antd";
import { S_TO_MIN_CONST } from "./constants";

const Clock = ({
  index,
  originalTime,
  shouldRun,
  shouldReset,
  startTheClock,
}: {
  index: number;
  originalTime: number;
  shouldRun: boolean;
  shouldReset: boolean;
  startTheClock: (index: number) => void;
}) => {
  const [spentTime, setSpentTime] = useState(0);

  useEffect(() => {
    setSpentTime(+(localStorage.getItem(`spentTime_${index}`) || 0));
  }, [shouldReset, index]);

  useEffect(() => {
    shouldRun && localStorage.setItem(`spentTime_${index}`, "" + spentTime);
  }, [spentTime, index, shouldRun]);

  useEffect(() => {
    const newInterval = setInterval(() => {
      shouldRun && setSpentTime((spentTime) => spentTime + 1);
    }, 1000);
    return () => clearInterval(newInterval);
  }, [shouldRun]);

  const start = (index: number) => {
    startTheClock(index);
  };

  const runColors = [
    ["#ee98fb", "#883997"],
    ["#e1ffb1", "#7da453"],
    ["#8bf6ff", "#0093c4"],
    ["#ffe97d", "#c88719"],
    ["#d3b8ae", "#725b53"],
    ["#c1d5e0", "#62757f"],
    ["#63a4ff", "#1976d2"],
  ];
  return (
    <Col>
      {" "}
      <Card
        style={{
          width: 300,
          float: "left",
          margin: "10px",
          backgroundColor:
            originalTime - spentTime < 1
              ? shouldRun
                ? "#b4004e"
                : "#ff77a9"
              : shouldRun
              ? runColors[index]?.[1] || "#338a3e"
              : runColors[index]?.[0] || "#98ee99",
        }}
        onClick={() => !shouldRun && start(index)}
      >
        <div style={{ transform: "rotate(180deg)" }}>
          <h3>
            left: {Math.round((originalTime - spentTime) / S_TO_MIN_CONST)} min
            ({originalTime - spentTime} s)
          </h3>
        </div>
        <div style={{ transform: "rotate(180deg)" }}>
          <h4 style={{ color: "#000" }}>
            spent: {Math.round(spentTime / S_TO_MIN_CONST)} min ({spentTime} s)
          </h4>
        </div>
        <div>
          {shouldRun &&
            Array.from(Array((spentTime % 61) + 1).keys()).map(() => ".")}
        </div>
        <div>
          <h4 style={{ color: "#000" }}>
            spent: {Math.round(spentTime / S_TO_MIN_CONST)} min ({spentTime} s)
          </h4>
        </div>
        <div>
          <h3>
            left: {Math.round((originalTime - spentTime) / S_TO_MIN_CONST)} min
            ({originalTime - spentTime} s)
          </h3>
        </div>
      </Card>
    </Col>
  );
};

export default Clock;
