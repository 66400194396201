import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Input, Row } from "antd";
import Clock from "./Clock";
import "antd/dist/reset.css";
import "./index.css";
import { S_TO_MIN_CONST } from "./constants";

const SET_CLOCKS_INIT = 2;
const SET_TIMES_S_INIT_M = 60;
const SET_TIMES_S_INIT_VALUE = SET_TIMES_S_INIT_M * S_TO_MIN_CONST;

const App = () => {
  const [clocks, setClocks] = useState(SET_CLOCKS_INIT);
  const [timeS, setTimeS] = useState(SET_TIMES_S_INIT_VALUE);
  const [runningIndex, setRunningIndex] = useState(-1);
  const [shouldReset, setShouldReset] = useState(false);
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const oldClocks = +(localStorage.getItem("setClocks") || SET_CLOCKS_INIT);
    onChangeNumber(oldClocks);
    const oldTimeS = +(
      localStorage.getItem("setTimeS") || SET_TIMES_S_INIT_VALUE
    );
    setTimeS(oldTimeS);
  }, [shouldReset]);

  const onChangeNumber = (value: number | null) => {
    console.log("setClocks", value);
    localStorage.setItem(`setClocks`, "" + (value || SET_CLOCKS_INIT));
    setClocks(value || 0);
  };

  const onChangeTime = (value: number | null) => {
    console.log("setTimeS", value);
    localStorage.setItem(`setTimeS`, "" + (value || 0) * S_TO_MIN_CONST);
    setTimeS((value || 0) * S_TO_MIN_CONST);
  };

  const startTheClock = (index: number) => {
    if (!isSet) {
      setIsSet(true);
    }
    setRunningIndex(index);
  };

  const clean = () => {
    pause();
    setIsSet(false);
    for (let i = 0; i < Math.max(10, clocks); i++) {
      localStorage.setItem(`spentTime_${i}`, "0");
    }
    setShouldReset(true);
    setTimeout(() => {
      setShouldReset(false);
    }, 1000);
  };

  const pause = () => {
    setRunningIndex(-1);
  };

  return (
    <div className="App">
      <>
        {!isSet ? (
          <>
            <h1 style={{ margin: "20px auto 0 auto", width: "fit-content" }}>
              {" "}
              Clocks by Znoj
            </h1>
            <Divider style={{ margin: "10px 0 0 0" }} orientation="left">
              Setup
            </Divider>
            <Row
              style={{ margin: "1px auto", width: "fit-content" }}
              align="middle"
              justify="center"
            >
              <Row style={{ margin: "10px" }}>
                <div style={{ margin: "10px", width: "fit-content" }}>
                  number of clocks:
                </div>{" "}
                <Input
                  allowClear
                  style={{ width: 80 }}
                  value={clocks}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeNumber(+e.target.value)
                  }
                />
              </Row>
              <Row style={{ margin: "10px" }}>
                <div style={{ margin: "10px", width: "fit-content" }}>
                  initial time:
                </div>{" "}
                <Input
                  allowClear
                  style={{ width: 100 }}
                  value={Math.round(timeS / S_TO_MIN_CONST)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeTime(+e.target.value)
                  }
                />
              </Row>
              <Button type="primary" onClick={() => clean()}>
                RESET clocks
              </Button>
            </Row>
          </>
        ) : (
          <>
            <Row
              style={{
                margin: "10px auto",
                width: "fit-content",
                height: "30px",
              }}
            >
              <div style={{ margin: "10px", width: "fit-content" }}>
                time: {timeS / S_TO_MIN_CONST} min ({timeS})
              </div>
              <Col style={{ margin: "2px 10px" }}>
                <Button
                  type="primary"
                  disabled={runningIndex > -1}
                  onClick={() => clean()}
                >
                  RESET
                </Button>
              </Col>
              <Col style={{ margin: "2px 10px" }}>
                <Button type="default" onClick={() => pause()}>
                  PAUSE
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Divider style={{ margin: "10px 0 0 0" }} orientation="left">
          Clocks
        </Divider>
        <Row
          style={{ margin: "10px auto", width: "fit-content" }}
          align="middle"
          justify="center"
        >
          {Array.from(Array(clocks).keys()).map((clockIndex: number) => {
            return (
              <Clock
                key={clockIndex}
                index={clockIndex}
                originalTime={timeS}
                shouldRun={runningIndex === clockIndex}
                shouldReset={shouldReset}
                startTheClock={startTheClock}
              />
            );
          })}
        </Row>
      </>
    </div>
  );
};

export default App;
